import React from "react";
function TheBrainTake() {
  return (
    <div id="wecare">
      <img
        src={`${process.env.PUBLIC_URL}/assets/Images/Home/THE BRAIN TAKE.png`}
        alt=""
      />
      <p>
        Equip yourself with reliable tools, gain new perspectives on stressful
        situations, and develop effective skills to manage your stress,
        fostering mental resilience and well-being.
      </p>
    </div>
  );
}

export default TheBrainTake;
